import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onShowBillingoModal_6fe82f21() {
    $('#fulfillment').prop('checked', $('#payment_mode option:selected').data('fulfillment') === 1);
    $('#mediated_service').prop('checked', $('#shipping_cost option:selected').data('mediated') === 1);

    if ($('#invoice_number').val().length) {
        $('#billingo-number-warning').removeClass('d-none');
    }
}

function onBillingoInvoiceForm_6fe82f21(event) {
    toggleLoading('#saveButton', '#loadingButton');
    toggleLoading('#saveButtonCFS', '#loadingButtonCFS');
    event.preventDefault();
    let form = $("#invoice-form");
    let fd = new FormData(form[0]);
    let inputs = $(this).find("input, select, button, textarea");
    let url = $('#billingo-helper').data('url');
    inputs.prop("disabled", true);

    fd.append('online_payment', $('#online_payment').val());
    fd.append('mediated_service', $('#mediated_service').is(':checked'));
    fd.append('fulfillment', $('#fulfillment').is(':checked'));
    fd.append('electronic_invoice', $('#electronic_invoice').is(':checked'));
    fd.append('other_type', $('#other_type').is(':checked'));

    $.ajax({
        url: url,
        type: 'POST',
        data: fd,
        cache: false,
        contentType: false,
        processData: false,
        async: true
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);
            $('#invoice_number').val(response.number);
            form.removeClass("was-validated");
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButton', '#loadingButton');
        toggleLoading('#saveButtonCFS', '#loadingButtonCFS');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initBillingoCreateInvoiceModal() {
    $(document).on('click', '#billingo-create-invoice', onShowBillingoModal_6fe82f21);
    $("#create-billingo-invoice-form").on("submit", onBillingoInvoiceForm_6fe82f21);
}

export function unloadBillingoCreateInvoiceModal() {
    $(document).off('click', '#billingo-create-invoice', onShowBillingoModal_6fe82f21);
    $("#create-billingo-invoice-form").off("submit", onBillingoInvoiceForm_6fe82f21);
}
