import '../../app/css/pages/app-invoice.css';
import '../../app/vendors/css/forms/select/select2.min.css';
import '../../app/css/plugins/forms/pickers/form-flat-pickr.css';
import '../../app/vendors/css/pickers/flatpickr/flatpickr.min.css';

import {
    initBillingoCreateInvoiceModal,
    unloadBillingoCreateInvoiceModal
} from "../modules/_billingo_create_invoice_modal";
import flatpickr from "flatpickr";
import Inputmask from 'inputmask';
import select2 from 'select2';
import repeater from 'jquery.repeater';
import {initFoxpostNewParcelModal, unloadFoxpostNewParcelModal} from "../modules/_foxpost_new_parcel_modal";
import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";
import Popover from "bootstrap/js/src/popover";

function renderLabels_f6bbc6ff(option) {
    if (!option.id) {
        return option.text;
    }

    return "<div style='color:#ffffff;background-color:" + $(option.element).data('color') + ";' class='badge badge-pill'> " + option.text + '</div>';
}

function onDocumentChange_f6bbc6ff() {
    $('#helper').data('unsaved', true);
}

function onBeforeUnload_f6bbc6ff() {
    let helper = $('#helper');

    if (helper.data('unsaved')) {
        return helper.data('nav');
    }
}

function onUpdateDueDate_f6bbc6ff() {
    let invoiceDue = $('#invoice_due');
    let date = new Date($('#invoice_date').val());
    let dueDays = $('#payment_mode option:selected').data('due_days');

    if (dueDays === '') {
        return;
    }

    date.setDate(date.getDate() + dueDays);

    if (invoiceDue.val().length > 1) {
        $('.due_date_warning').removeClass('d-none');
    }

    let month = '' + (date.getMonth() + 1), day = '' + date.getDate(), year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    invoiceDue.val([year, month, day].join('.') + '.');
}

function onSelect2Select_f6bbc6ff(e) {
    let data = e.params.data;
    let digits = getDigits();
    let target = $(e.target).parents('.product-details-border');

    target.find('[aria-describedby="gross_product_cost"]').val(data.single_gross_price.toFixed(digits));
    target.find('[aria-describedby="net_product_cost"]').val(data.single_net_price.toFixed(digits));
    target.find('[aria-describedby="product_sku"]').val(data.SKU);
    target.find('[aria-describedby="product_barcode"]').val(data.barcode);
    target.find('[aria-describedby="product_acc_weight"]').val(data.accountable_weight);
    target.find('[aria-describedby="product_total_weight"]').val(data.total_weight);
    target.find('[aria-describedby="product_count"]').prop("max", data.on_stock).val(Math.min(0, data.on_stock));
    target.find('[aria-describedby="product_count"]').parent().find('.input-group-text').html('/' + data.on_stock);
    target.find('.container-info').text(data.container_info);
    target.find('[aria-describedby="product_vat_rate"]').val(data.vat_percent);

    updateControls();
}

function onCurrencyChange_f6bbc6ff() {
    $('.currency').each(function () {
        $(this).text($('#currency').val());
    });
}

function onCodChange_f6bbc6ff() {
    updateControls();
}

function onShippingCostChange_f6bbc6ff() {
    let selected = $("#shipping_cost option:selected");

    let grossCost = $('#gross_shipping_cost_value');
    let grossReal = $('#gross_shipping_real');
    let grossCod = $('#gross_cod');

    if (grossCost.val().length === 0) {
        grossCost.val(selected.data('cost'));
    }

    if (grossCod.val().length === 0) {
        grossCod.val(selected.data('cod'));
    }

    grossReal.val(selected.data('real'));
    $("#gross_shipping_stored").val(selected.data('cost'));

    updateControls();
}

function onSaveButtonClick_f6bbc6ff(event) {
    let form = $("#invoice-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();

        let control = $('.form-control:invalid').first();
        let id = control.parents('.tab-pane').prop('id');
        let element = control.closest('input').prop('id');

        $('#tabs a[href="#' + id + '"]').tab('show');
        $('#' + element).focus();

        setTimeout(function () {
            $('#' + element).focus();
            $("body,html").animate({
                scrollTop: $("body").offset().top
            }, 100);
        }, 300);
    }

    form.addClass('was-validated');
}

function onApiTrack_f6bbc6ff() {
    let helper = $('#helper');

    if ($(this).hasClass("text-danger")) {
        if (!confirm(helper.data('confirm'))) {
            return;
        }
    }

    showLoading();
    toggleLoading('#saveButton', '#loadingButton');

    let track = $("#api-track");
    let form = $("#invoice-form");
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = helper.data('track-url');
    inputs.prop("disabled", true);
    track.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        Swal.close();
        if (response.success) {
            track.removeClass("text-primary");
            track.addClass("text-danger");
            track.prop("disabled", false);
            swalSuccess(response.data);
        } else {
            swalError(response.data);
            track.prop("disabled", false);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButton', '#loadingButton');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onApiShip_f6bbc6ff() {
    let helper = $('#helper');

    if ($(this).hasClass("text-danger")) {
        if (!confirm(helper.data('confirm'))) {
            return;
        }
    }

    let ship = $("#api-ship");
    if (!Inputmask("datetime", {
        inputFormat: "yyyy.mm.dd HH:MM",
        placeholder: "yyyy.mm.dd. hh:ii",
        leapday: "-02-29",
        separator: "."
    }).mask(document.getElementById("invoice-postal-date")).isComplete()) {
        return alert(helper.data('invalid-postal'));
    }

    showLoading();
    toggleLoading('#saveButton', '#loadingButton');

    let form = $("#invoice-form");
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = helper.data('shipping-url');
    inputs.prop("disabled", true);
    ship.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        Swal.close();
        if (response.success) {
            ship.removeClass("text-primary");
            ship.addClass("text-danger");
            ship.prop("disabled", false);
            swalSuccess(response.data);
        } else {
            swalError(response.data);
            ship.prop("disabled", false);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButton', '#loadingButton');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onApiMail_f6bbc6ff() {
    let helper = $('#helper');
    if ($(this).hasClass("text-danger")) {
        if (!confirm(helper.data('confirm'))) {
            return;
        }
    }

    showLoading();
    toggleLoading('#saveButton', '#loadingButton');

    let mail = $("#api-mail");
    let form = $("#invoice-form");
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = helper.data('email-url');
    inputs.prop("disabled", true);
    mail.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        Swal.close();
        if (response.success) {
            mail.removeClass("text-primary");
            mail.addClass("text-danger");
            mail.prop("disabled", false);
            swalSuccess(response.data);
        } else {
            swalError(response.data);
            mail.prop("disabled", false);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButton', '#loadingButton');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onPaymentNotify_f6bbc6ff() {
    let helper = $('#helper');
    if ($(this).hasClass("text-danger")) {
        if (!confirm(helper.data('confirm'))) {
            return;
        }
    }

    showLoading();
    toggleLoading('#saveButton', '#loadingButton');

    let mail = $("#api-payment-notify");
    let form = $("#invoice-form");
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = helper.data('notify-url');
    inputs.prop("disabled", true);
    mail.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        Swal.close();
        if (response.success) {
            mail.removeClass("text-primary");
            mail.addClass("text-danger");
            mail.prop("disabled", false);
            swalSuccess(response.data);
        } else {
            swalError(response.data);
            mail.prop("disabled", false);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButton', '#loadingButton');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onComplete_f6bbc6ff() {
    let helper = $('#helper');
    if ($(this).hasClass("text-success")) {
        if (!confirm(helper.data('confirm-complete'))) {
            return;
        }
    }

    showLoading();
    toggleLoading('#saveButton', '#loadingButton');

    let complete = $("#complete-button");
    let completed = complete.hasClass('text-success');
    let form = $("#invoice-form");
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = helper.data('complete-url');
    inputs.prop("disabled", true);
    complete.prop("disabled", true);

    if (completed) {
        fd.append('completed', "1");
    } else {
        fd.append('completed', "0");
    }

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        Swal.close();
        if (response.success) {
            if (completed) {
                $('#order_status').val('processing').trigger('change');
                complete.removeClass("text-success").addClass('text-primary');
            } else {
                $('#order_status').val('completed').trigger('change');
                complete.addClass("text-success").removeClass('text-primary');
            }

            complete.prop("disabled", false);
            swalSuccess(response.data);

            if (completed) {
                location.reload();
            }
        } else {
            swalError(response.data);
            complete.prop("disabled", false);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButton', '#loadingButton');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function addDeleteProduct_f6bbc6ff() {
    let helper = $('#helper');

    $('.product-select:not(.select2-hidden-accessible)').select2({
        ajax: {
            url: helper.data('product-list'),
            language: $('html').attr('lang'),
            dataType: 'json',
            delay: 1000,
            cache: true,
            type: 'post',
            async: true,
            data: function (params) {
                return {
                    q: params.term,
                    page: params.page
                };
            },
            processResults: function (data, params) {
                params.page = params.page || 1;

                return {
                    results: data.items,
                    pagination: {
                        more: (params.page * 30) < data.total_count
                    }
                };
            }
        },
        placeholder: helper.data('select-product'),
        escapeMarkup: function (markup) {
            return markup;
        },
        minimumInputLength: 1,
        templateResult: formatProduct,
        templateSelection: formatProductSelection
    });
}

function onInvoiceSubmit_f6bbc6ff(event) {
    let url = '';
    let helper = $('#helper');

    if (helper.data('edit-mode') === 1) {
        url = helper.data('edit-url');
    } else {
        url = helper.data('add-url');
    }

    showLoading();
    toggleLoading('#saveButton', '#loadingButton');
    event.preventDefault();
    let form = $(this);
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    fd.append('products', JSON.stringify(getTableData()));
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            helper.data('unsaved', false);
            swalSuccess(response.data);
            form.removeClass("was-validated");

            if (helper.data('edit-mode') === 0) {
                form.trigger("reset");
                $(".repeater-wrapper:not(.d-none)").remove();
            } else {
                let currentStatus = $('#order_status option:selected');
                let statusText = $('.invoice-logo.status-text');

                statusText.text(currentStatus.text());
                statusText.css('color', currentStatus.data('color') + '!important');
            }
        } else {
            swalError(response.data);
        }
    }).fail(function () {
        swalUnknown(e);
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButton', '#loadingButton');
    });
}

function onGrossPriceChange_f6bbc6ff(event) {
    let digits = getDigits();
    let current = parseFloat($(event.target).val());
    let vatRate = parseFloat($(event.target).parents('.product-price-div').parent().find('[aria-describedby="product_vat_rate"]').val()) / 100 + 1;
    let grossCost = $(event.target).parents('.product-details-border').find('[aria-describedby="gross_product_cost"]');

    $(event.target).parents('.product-details-border').find('[aria-describedby="profit"]').val((current - grossCost.val()).toFixed(digits));
    $(event.target).parents('.product-price-div').find('[aria-describedby="product_net_price"]').val((current / vatRate).toFixed(digits));
    $(event.target).parents('.product-price-div').parent().find('[aria-describedby="product_vat"]').val((current - current / vatRate).toFixed(digits));

    updateControls();
}

function onNetPriceChange_f6bbc6ff(event) {
    let digits = getDigits();
    let current = parseFloat($(event.target).val());
    let vatRate = parseFloat($(event.target).parents('.product-price-div').parent().find('[aria-describedby="product_vat_rate"]').val()) / 100 + 1;
    let grossCost = $(event.target).parents('.product-details-border').find('[aria-describedby="gross_product_cost"]');

    $(event.target).parents('.product-details-border').find('[aria-describedby="profit"]').val((current * vatRate - grossCost.val()).toFixed(digits));
    $(event.target).parents('.product-price-div').find('[aria-describedby="product_gross_price"]').val((current * vatRate).toFixed(digits));
    $(event.target).parents('.product-price-div').parent().find('[aria-describedby="product_vat"]').val((current * vatRate - current).toFixed(digits));

    updateControls();
}

function onVatChange_f6bbc6ff(event) {
    let digits = getDigits();
    let rate = parseFloat($(event.target).val());
    let grossPrice = $(event.target).parents('.product-details-border').find('[aria-describedby="product_gross_price"]');
    let netPrice = $(event.target).parents('.product-details-border').find('[aria-describedby="product_net_price"]');

    let netCost = $(event.target).parents('.product-details-border').find('[aria-describedby="net_product_cost"]');
    let grossCost = $(event.target).parents('.product-details-border').find('[aria-describedby="gross_product_cost"]');

    netPrice.val((parseFloat(grossPrice.val()) / (rate / 100 + 1)).toFixed(digits));
    netCost.val((parseFloat(grossCost.val()) / (rate / 100 + 1)).toFixed(digits));

    $(event.target).parents('.product-details-border').find('[aria-describedby="profit"]').val((grossPrice.val() - grossCost.val()).toFixed(digits));
    $(event.target).parents('.product-details-border').find('[aria-describedby="product_vat"]').val((parseFloat(grossPrice.val()) - parseFloat(netPrice.val())).toFixed(digits));

    updateControls();
}

export function initEditOrderPage() {
    let helper = $('#helper');
    let orderStatus = $('#order_status');

    window.Inputmask = Inputmask;
    window.flatpickr = flatpickr;
    window.repeater = repeater;
    window.select2 = select2;

    if ($('html').attr('lang') === 'hu') {
        require('select2/dist/js/i18n/hu.js');
        const hu = require("flatpickr/dist/l10n/hu").default.hu;
        flatpickr.localize(hu);
    }

    if (helper.data('edit-mode') === 1) {
        if (helper.data('billingo') === 1) {
            initBillingoCreateInvoiceModal();
        }

        if (helper.data('foxpost') === 1) {
            initFoxpostNewParcelModal();
        }
    }

    window.formatProduct = function (product) {
        if (product.loading) {
            return product.text;
        }

        return "<div class='select2-result-repository clearfix d-flex'>" +
            "<div class='select2-result-repository__avatar mr-2'>" +
            "<img alt='product_image' src='" + helper.data('assets') + "/img/product/" + product.image_url + "' class='width-2 height-2 mt-1 rounded' />" +
            "</div>" +
            "<div class='select2-result-repository__meta'>" +
            "<div class='select2-result-repository__title fs-lg fw-500'>" + product.product_name + "</div>" +
            "<div class='select2-result-repository__statistics d-flex font-small-3'>" +
            "<div class='select2-result-repository__forks mr-2'><b>" + helper.data('sku') + ":</b> " + product.SKU + "</div>" +
            "<div class='select2-result-repository__stargazers mr-2'><b>" + helper.data('cost') + ":</b> " + product.avg_gross_price + "</div>" +
            "<div class='select2-result-repository__watchers mr-2'><b>" + helper.data('container') + ":</b> " + product.container_info + "</div>" +
            "<div class='select2-result-repository__watchers mr-2'><b>" + helper.data('stock') + ":</b> " + product.on_stock + " " + helper.data('abbr') + "</div>" +
            "</div>" +
            "</div>" +
            "</div>";
    };

    window.formatProductSelection = function (product) {
        return product.product_name || product.text;
    };

    window.showLoading = function () {
        Swal.fire({
            title: helper.data('in-progress'),
            willOpen() {
                Swal.showLoading();
            },
            didClose() {
                Swal.hideLoading();
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showCancelButton: false,
            showConfirmButton: false
        }).then();
    };

    window.getTableData = function () {
        let data = [];

        $('div#invoice_tab_2 .repeater-wrapper:not(:first)').each(function () {
            let element = $(this);
            let name = '';

            if (element.find('[aria-describedby="product_id"]').hasClass('select2-hidden-accessible')) {
                name = element.find('[aria-describedby="product_id"]').select2('data')[0].text;

                if (name === '') {
                    name = element.find('[aria-describedby="product_id"]').select2('data')[0].product_name;
                }
            }

            if (element.find('[aria-describedby="product_id"]').val() !== null) {
                data.push({
                    "product_name": name,
                    "product_id": parseInt(element.find('[aria-describedby="product_id"]').val()),
                    "product_sku": element.find('[aria-describedby="product_sku"]').val() || '',
                    "product_barcode": element.find('[aria-describedby="product_barcode"]').val() || '',
                    "product_count": parseInt(element.find('[aria-describedby="product_count"]').val()) || 0,
                    "product_gross_price": parseFloat(element.find('[aria-describedby="product_gross_price"]').val()) || 0,
                    "product_net_price": parseFloat(element.find('[aria-describedby="product_net_price"]').val()) || 0,
                    "product_vat_rate": parseInt(element.find('[aria-describedby="product_vat_rate"]').val()) || 0,
                    "product_vat": parseFloat(element.find('[aria-describedby="product_vat"]').val()) || 0,
                    "gross_product_cost": parseFloat(element.find('[aria-describedby="gross_product_cost"]').val()) || 0,
                    "net_product_cost": parseFloat(element.find('[aria-describedby="net_product_cost"]').val()) || 0,
                    "product_acc_weight": parseInt(element.find('[aria-describedby="product_acc_weight"]').val()) || 0,
                    "product_total_weight": parseInt(element.find('[aria-describedby="product_total_weight"]').val()) || 0,
                });
            }
        });

        return data;
    };

    window.updateControls = function () {
        let tableData = getTableData();
        let vatRate = 0, vat = 0;
        let netTotal, grossTotal;
        let totalWeight = 0, accountableWeight = 0;
        let grossProductCost = 0, netProductCost = 0;
        let grossProductPrice = 0, netProductPrice = 0;
        let count, grossTotalDiff, netTotalDiff;
        let digits = getDigits();

        tableData.forEach(function (item) {
            vatRate = Math.max(vatRate, item.product_vat_rate);
        });

        $('.vat_rate_info').text(vatRate);
        vatRate /= 100;

        let grossShippingCost = $('#gross_shipping_cost_value').val();
        let grossShippingReal = $('#gross_shipping_real').val();
        let grossShippingStored = $('#gross_shipping_stored').val();
        let grossCod = $('#gross_cod').val();

        let netShippingCost = $('#net_shipping_cost_value');
        let netShippingReal = $('#net_shipping_real');
        let netShippingStored = $('#net_shipping_stored');
        let netCod = $('#net_cod');

        netShippingStored.val((parseFloat(grossShippingStored) / (vatRate + 1)).toFixed(digits));
        netShippingCost.val((parseFloat(grossShippingCost) / (vatRate + 1)).toFixed(digits));
        netShippingReal.val((parseFloat(grossShippingReal) / (vatRate + 1)).toFixed(digits));
        netCod.val((parseFloat(grossCod) / (vatRate + 1)).toFixed(digits));

        netShippingCost = netShippingCost.val();
        netShippingReal = netShippingReal.val();
        netCod = netCod.val();

        if (isNaN(grossShippingCost) || grossShippingCost === '') {
            grossShippingCost = 0;
        } else {
            grossShippingCost = Number.parseFloat(grossShippingCost);
        }

        if (isNaN(netShippingCost) || netShippingCost === '') {
            netShippingCost = 0;
        } else {
            netShippingCost = Number.parseFloat(netShippingCost);
        }

        if (isNaN(grossShippingReal) || grossShippingReal === '') {
            grossShippingReal = 0;
        } else {
            grossShippingReal = Number.parseFloat(grossShippingReal);
        }

        if (isNaN(netShippingReal) || netShippingReal === '') {
            netShippingReal = 0;
        } else {
            netShippingReal = Number.parseFloat(netShippingReal);
        }

        if (isNaN(grossCod) || grossCod === '') {
            grossCod = 0;
        } else {
            grossCod = Number.parseFloat(grossCod);
        }

        if (isNaN(netCod) || netCod === '') {
            netCod = 0;
        } else {
            netCod = Number.parseFloat(netCod);
        }

        for (let i = 0; i < tableData.length; i++) {
            count = parseInt(tableData[i].product_count, 10);
            vat += parseFloat(tableData[i].product_vat) * count;
            grossProductPrice += parseFloat(tableData[i].product_gross_price) * count;
            netProductPrice += parseFloat(tableData[i].product_net_price) * count;
            grossProductCost += parseFloat(tableData[i].gross_product_cost) * count;
            netProductCost += parseFloat(tableData[i].net_product_cost) * count;
            totalWeight += parseInt(tableData[i].product_total_weight, 10) * count;
            accountableWeight += parseInt(tableData[i].product_acc_weight, 10) * count;
        }

        vat += grossShippingCost * vatRate + grossCod * vatRate;

        grossTotal = grossProductPrice + grossCod + grossShippingCost;
        netTotal = netProductPrice + netCod + netShippingCost;

        grossTotalDiff = grossTotal - grossProductCost - grossShippingReal;
        netTotalDiff = netTotal - netProductCost - netShippingReal;

        $('#net_product_span .invoice-total-amount-value').text(netProductPrice.toFixed(digits));
        $('#gross_product_span .invoice-total-amount-value').text(grossProductPrice.toFixed(digits));
        $('#net_postal_cost_span .invoice-total-amount-value').text((netShippingCost + netCod).toFixed(digits));
        $('#gross_postal_cost_span .invoice-total-amount-value').text((grossShippingCost + grossCod).toFixed(digits));
        $('#product_vat_span .invoice-total-amount-value').text(vat.toFixed(digits));
        $('#net_total_span .invoice-total-amount-value').text(netTotal.toFixed(digits));
        $('#gross_total_span .invoice-total-amount-value').text(grossTotal.toFixed(digits));
        $('#net_product_cost_span .invoice-total-amount-value').text(netProductCost.toFixed(digits));
        $('#gross_product_cost_span .invoice-total-amount-value').text(grossProductCost.toFixed(digits));
        $('#net_shipping_real_span .invoice-total-amount-value').text(netShippingReal.toFixed(digits));
        $('#gross_shipping_real_span .invoice-total-amount-value').text(grossShippingReal.toFixed(digits));
        $('#net_profit_span .invoice-total-amount-value').text(netTotalDiff.toFixed(digits));
        $('#gross_profit_span .invoice-total-amount-value').text(grossTotalDiff.toFixed(digits));
        $('#total_weight_span .invoice-total-amount-value').text(totalWeight);
        $('#acc_weight_span .invoice-total-amount-value').text(accountableWeight);

        $('#gross_product_price').val(grossProductPrice.toFixed(digits));
        $('#net_product_price').val(netProductPrice.toFixed(digits));
        $('#gross_total').val(grossTotal.toFixed(digits));
        $('#net_total').val(netTotal.toFixed(digits));
        $('#gross_invoice_cost').val(grossProductCost.toFixed(digits));
        $('#net_invoice_cost').val(netProductCost.toFixed(digits));
        $('#gross_full_difference').val(grossTotalDiff.toFixed(digits));
        $('#net_full_difference').val(netTotalDiff.toFixed(digits));
        $('#total_weight').val(totalWeight);
        $('#accountable_weight').val(accountableWeight);
        $('#gross_shipping_difference').val((grossShippingCost + grossCod - grossShippingReal).toFixed(digits));
        $('#net_shipping_difference').val((netShippingCost + netCod - netShippingReal).toFixed(digits));

        $('.invoice-total-amount-value').each(function () {
            $(this).text($(this).text().toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."));
        });
    };

    window.getDigits = function () {
        let currency = $('#currency option:selected').data('digits');
        let digits = $('#digits').val();

        if (currency === undefined) {
            return digits;
        } else {
            return currency;
        }
    };

    window.prepareFoxpost = function (place_id) {
        let address = $('#foxpost-address');
        let foxpostName = $('#foxpost-name');
        let name = $('#s_last_name').val() + ' ' + $('#s_first_name').val();

        if (name.length < 3) {
            name = $('#b_last_name').val() + ' ' + $('#b_first_name').val();
        }

        if (name.length < 3) {
            name = $('#invoice_name').val();
        }

        foxpostName.val(name);
        $('#foxpost-invoice-id').val($('#invoice').val());
        $('#foxpost-email').val($('#email_address').val());
        $('#foxpost-phone').val($('#phone_number').val());
        $('#foxpost-postcode').val($('#s_zipcode').val());
        $('#foxpost-city').val($('#s_city').val());
        address.val($('#s_address_1').val() + ' ' + $('#s_address_2').val());
        $('#foxpost-reference').val($('#order_id').val());
        $('#foxpost-address-limit').html(address.val().length.toString());
        $('#foxpost-name-limit').html(foxpostName.val().length.toString());

        let optionOne = $('#apt_id option[value="' + place_id + '"]');
        let optionTwo = $('#apt_id option[data-operator="' + place_id + '"]');

        if (optionOne.length > 0) {
            $('#apt_id').val(optionOne.val()).trigger('change');
        } else if (optionTwo.length > 0) {
            $('#apt_id').val(optionTwo.val()).trigger('change');
        }

        if ($('#gross_cod').val() > 0 || ['cod', 'cash_on_delivery'].includes($('#payment_mode option:selected').data('slug'))) {
            $('#foxpost-cod').val($('#gross_total').val());
        }

        if (place_id.length > 0 && place_id !== '0') {
            $('#apt_s').prop('checked', true);
            $('.apt-row').removeClass('d-none');
            $('.home-row').addClass('d-none');
            address.prop('pattern', '.{0,256}');
        } else {
            $('#home_s').prop('checked', true);
            $('.home-row').removeClass('d-none');
            $('.apt-row').addClass('d-none');
            address.prop('pattern', '.{0,30}');
        }
    };

    $('[aria-describedby="product_net_price"]:not(:first)').each(function (i, e) {
        $(e).val(Number($(e).val()).toFixed(getDigits()));
    });

    updateControls();

    $('.source-item').repeater({
        isFirstItemUndeletable: true,
        show: function () {
            $('.repeater-wrapper:not(:first)').removeClass('d-none');
            $(this).slideDown();

            let popoverTriggerList = [].slice.call(document.querySelectorAll('.has-tooltip'));

            popoverTriggerList.map(function (popoverTriggerEl) {
                return new Popover(popoverTriggerEl, {});
            });

            addDeleteProduct_f6bbc6ff();

            if (feather) {
                feather.replace({width: 14, height: 14});
            }

            $('.currency').each(function () {
                $(this).text($('#currency').val());
            });
        },
        hide: function (deleteElement) {
            $(this).slideUp(deleteElement, function () {
                $(this).remove();
                updateControls();
            });
        }
    });

    helper.data('unsaved', false);

    orderStatus.wrap("<div class='position-relative mb-1'></div>").select2({
        placeholder: helper.data('select-option'),
        dropdownParent: orderStatus.parent(),
        minimumResultsForSearch: -1,
        templateResult: renderLabels_f6bbc6ff,
        templateSelection: renderLabels_f6bbc6ff,
        escapeMarkup: function (es) {
            return es;
        }
    });

    let popoverTriggerList = [].slice.call(document.querySelectorAll('.has-tooltip'));

    popoverTriggerList.map(function (popoverTriggerEl) {
        return new Popover(popoverTriggerEl, {});
    });

    $('.datepicker').flatpickr({
        dateFormat: "Y.m.d.",
        allowInput: true
    });

    $('.invoice-total-amount-value').each(function () {
        $(this).text($(this).text().toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."));
    });

    $('.currency').each(function () {
        $(this).text($('#currency').val());
    });

    if (helper.data('edit-mode') === 1) {
        Inputmask("datetime", {
            inputFormat: "yyyy.mm.dd HH:MM",
            placeholder: "yyyy.mm.dd. hh:ii",
            leapday: "-02-29",
            separator: "."
        }).mask(document.getElementById("postal_time"));
    }

    $(document).on('change', ':input', onDocumentChange_f6bbc6ff);
    $(window).on('beforeunload', onBeforeUnload_f6bbc6ff);
    $(document).on("select2:select", ".product-select", onSelect2Select_f6bbc6ff);
    $(document).on('input change', '#invoice_tab_3 input', onCodChange_f6bbc6ff);
    $(document).on('input change', '.gross-price-control', onGrossPriceChange_f6bbc6ff);
    $(document).on('input change', '.net-price-control', onNetPriceChange_f6bbc6ff);
    $(document).on('input change', '[aria-describedby="product_vat_rate"], [aria-describedby="product_count"]', onVatChange_f6bbc6ff);
    $('#currency').on('change', onCurrencyChange_f6bbc6ff);
    $('#payment_mode, #invoice_date').on('change', onUpdateDueDate_f6bbc6ff);
    $("#shipping_cost").on("change", onShippingCostChange_f6bbc6ff);
    $("#saveButton").on("click", onSaveButtonClick_f6bbc6ff);
    $("#api-track").on("click", onApiTrack_f6bbc6ff);
    $("#api-ship").on("click", onApiShip_f6bbc6ff);
    $("#api-mail").on("click", onApiMail_f6bbc6ff);
    $("#api-payment-notify").on("click", onPaymentNotify_f6bbc6ff);
    $("#complete-button").on("click", onComplete_f6bbc6ff);
    $("#invoice-form").on('submit', onInvoiceSubmit_f6bbc6ff);
    $("#invoice_tab_2-tab").on("click", addDeleteProduct_f6bbc6ff);
}

export function unloadEditOrderPage() {
    delete window.Inputmask;
    delete window.flatpickr;
    delete window.repeater;
    delete window.select2;

    delete window.formatProduct;
    delete window.formatProductSelection;
    delete window.showLoading;
    delete window.getTableData;
    delete window.updateControls;
    delete window.getDigits;
    delete window.prepareFoxpost;

    let helper = $('#helper');

    $('#order_status').select2('destroy');
    $('.product-select.select2-hidden-accessible').select2('destroy');
    $('.flatpickr-calendar').remove();

    $(document).off('change', ':input', onDocumentChange_f6bbc6ff);
    $(window).off('beforeunload', onBeforeUnload_f6bbc6ff);
    $(document).off("select2:select", ".product-select", onSelect2Select_f6bbc6ff);
    $(document).off('input change', '#invoice_tab_3 input', onCodChange_f6bbc6ff);
    $(document).off('input change', '.gross-price-control', onGrossPriceChange_f6bbc6ff);
    $(document).off('input change', '.net-price-control', onNetPriceChange_f6bbc6ff);
    $(document).off('input change', '[aria-describedby="product_vat_rate"], [aria-describedby="product_count"]', onVatChange_f6bbc6ff);
    $('#currency').off('change', onCurrencyChange_f6bbc6ff);
    $('#payment_mode, #invoice_date').off('change', onUpdateDueDate_f6bbc6ff);
    $("#shipping_cost").off("change", onShippingCostChange_f6bbc6ff);
    $("#saveButton").off("click", onSaveButtonClick_f6bbc6ff);
    $("#api-track").off("click", onApiTrack_f6bbc6ff);
    $("#api-ship").off("click", onApiShip_f6bbc6ff);
    $("#api-mail").off("click", onApiMail_f6bbc6ff);
    $("#api-payment-notify").off("click", onPaymentNotify_f6bbc6ff);
    $("#complete-button").off("click", onComplete_f6bbc6ff);
    $("#invoice-form").off('submit', onInvoiceSubmit_f6bbc6ff);
    $("#invoice_tab_2-tab").off("click", addDeleteProduct_f6bbc6ff);

    if (helper.data('edit-mode') === 1) {
        if (helper.data('billingo') === 1) {
            unloadBillingoCreateInvoiceModal();
        }

        if (helper.data('foxpost') === 1) {
            unloadFoxpostNewParcelModal();
        }
    }
}
